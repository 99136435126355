/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseProductsResponse, useSimpleShoppingCart } from "@with-nx/ecommerce";
import {
  Currency,
  Formatter,
  useDropCollections,
  usePricing,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { DesignedCard, LoadingShimmer, Rule } from "@with-nx/simple-ui/atoms";
import dynamic from "next/dynamic";
import { useState } from "react";

import { RedirectCartModal } from "../w/redirect-cart-modal/redirect-cart-modal";
import { Box } from "simple-effing-primitive-layout";

const DigitalDropPopModal = dynamic(
  () => import("./digital-drop-pop-modal/digital-drop-pop-modal")
);

const ReusableProductGrid = ({
  loading = false,
  products,
  productCategory,
  licensor,
  hidePrice = false,
}: {
  loading?: boolean;
  products: UseProductsResponse;
  productCategory: string;
  licensor?: number;
  hidePrice?: boolean;
}) => {
  const basket = useSimpleShoppingCart();
  const region = useServerRegion();
  const collections = useDropCollections();
  const pricing = usePricing({
    type: "DIGITAL_DROP",
    region: region?.id,
  });
  const [modal, _modal] = useState(false);
  const [popModal, _popModal] = useState(false);
  const [selectedIndex, _selectedIndex] = useState(0);

  const [productTitle, _productTitle] = useState("");

  if (loading || !products) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 pt-8">
        {Array.from(Array(12).keys()).map((item) => (
          <div key={item}>
            <LoadingShimmer parse="w:100% pt:56.25% br:5 mb:24" />
          </div>
        ))}
      </div>
    );
  }

  const selectedDrop = products?.result?.[selectedIndex];

  const getPreviewImage = (item) =>
    item.attachments?.find((i: any) => i.name === "preview_still")?.blob
      ?.signedUrl;

  const getPreviewVideo = (item) =>
    item.attachments?.find((i: any) => i.name === "preview_video")?.blob
      ?.signedUrl;

  const handleAddToCart = (item) => {
    basket.add(basket.transform(item)?.product?.id, "DIGITAL_DROP");
  };

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 ${
        licensor
          ? "lg:grid-cols-4 xl:grid-cols-4"
          : "lg:grid-cols-3 xl:grid-cols-3"
      } gap-x-8 pt-8`}
    >
      {products?.result?.map((item, index) => {
        const _drop = item?.type?.includes("DigitalDrop");

        const _licensor =
          item?.scenicProjections?.[0]?.product?.licensors?.find(
            (l) => l?.region?.id === region.id
          )?.licensor?.name ||
          item?.choreoGuide?.product?.licensors?.find(
            (l) => l?.region?.id === region.id
          )?.licensor?.name ||
          "";

        const shouldHideLogo: boolean = (() => {
          if (item?.forceShowLogo) {
            return false;
          }

          if (productCategory === "global") {
            if (
              !item?.choreoGuide?.product &&
              !item?.scenicProjections?.find((i) => Boolean(i.product))
            ) {
              return true;
            }
          }

          if (productCategory === "choreo-guides") {
            if (!item?.choreoGuide?.product) {
              return true;
            }
          }

          if (productCategory === "scenic-projections") {
            if (!item?.scenicProjections?.find((i) => Boolean(i.product))) {
              return true;
            }
          }

          return false;
        })();

        const itemLogo = shouldHideLogo
          ? undefined
          : item.attachments?.find((i) => i.name === "logo")?.blob?.signedUrl;

        const itemTitle = !itemLogo || shouldHideLogo ? item.title : undefined;

        const itemColor =
          item?.logoColor || item?.showBrand?.logoColor || "#000000";
        const title =
          item.title || (item as unknown as any)?.name?.split("(")?.[0]?.trim();

        return (
          <div
            key={item.id}
            className="transition duration-300 ease-in-out transform opacity-100 group-hover:opacity-100 group-hover:scale-100"
            style={{
              transitionDelay: `${index * 50}ms`,
              zIndex: products?.result?.length - index,
              position: "relative",
            }}
          >
            {_drop ? (
              <DesignedCard
                size="16x9"
                gradient={{
                  direction: "bottom",
                }}
                image={
                  item.attachments?.find((i: any) => i.name === "preview_still")
                    ?.blob?.signedUrl
                }
                video={
                  item.attachments?.find((i: any) => i.name === "preview_video")
                    ?.blob?.signedUrl
                }
                pop={true}
                renderPop={() => {
                  _selectedIndex(index);
                  _popModal(true);
                }}
                controls={false}
                outside={
                  hidePrice
                    ? {
                        children: (
                          <Box>
                            <Rule
                              parse="ta:center"
                              color="var(--white-base)"
                              rule="_h3"
                              display="block"
                            >
                              {title}
                            </Rule>
                            <Rule
                              color="var(--blue-80)"
                              rule="lt"
                              top={5}
                              display="block"
                              parse="ta:center"
                            >
                              {item.id}
                            </Rule>
                          </Box>
                        ),
                      }
                    : {
                        title,
                        subtitle: `${item.id}`,
                        price: {
                          price: Currency.format(
                            parseFloat(pricing?.price) || 0,
                            region.currency.code
                          ),
                        },
                      }
                }
                inside={{
                  cart: {
                    added: basket.added(basket.transform(item)?.product?.id),
                    press: () => {
                      if (!basket.authenticated) {
                        return;
                      }
                      basket.add(
                        basket.transform(item)?.product?.id,
                        "DIGITAL_DROP"
                      );
                      _productTitle(`Digital Backdrop "${item?.name}"`);
                      _modal(true);
                    },
                    authenticated: basket.authenticated,
                    properties: {
                      product: basket.transform(item)?.product?.id,
                      type: "DIGITAL_DROP",
                    },
                    cypressTitle: Formatter.slugify(item.name),
                  },
                }}
                properties={{
                  bottom: 36,
                }}
                playable
              />
            ) : (
              <DesignedCard
                native={{
                  cypress: `show-${
                    item?.slug || item?.showBrand?.slug || item?.id
                  }`,
                }}
                size="16x9"
                gradient={{ direction: "bottom" }}
                inside={{
                  image: itemLogo,
                  large: itemTitle,
                  color: itemColor,
                }}
                outside={{
                  title: item.title || item.name?.split("(")?.[0]?.trim(),
                  subtitle: _licensor,
                }}
                properties={{
                  bottom: 36,
                }}
                href={(() => {
                  if (_drop) {
                    return undefined;
                  }
                  if (productCategory === "choreo-guides") {
                    return `/shows/${item?.showBrand?.slug || item?.slug}/${
                      item.slug
                    }/choreography-guides`;
                  }

                  return `/shows/${item?.showBrand?.slug || item?.slug}/${
                    item.slug
                  }/scenic-projections`;
                })()}
              />
            )}
          </div>
        );
      })}

      {modal && (
        <RedirectCartModal modal={modal} _modal={_modal} title={productTitle} />
      )}

      {popModal && selectedDrop && (
        <DigitalDropPopModal
          popModal={popModal}
          _popModal={_popModal}
          selectedDrop={selectedDrop}
          basket={basket}
          video={getPreviewVideo(selectedDrop)}
          image={getPreviewImage(selectedDrop)}
          onAddToCart={handleAddToCart}
          onGoNext={() => {
            if (selectedIndex < products?.result?.length) {
              _selectedIndex(selectedIndex + 1);
            }
          }}
          onGoPrevious={() => {
            if (selectedIndex > 0) {
              _selectedIndex(selectedIndex - 1);
            }
          }}
          disablePrevious={selectedIndex === 0}
          disableNext={selectedIndex === products?.result?.length - 1}
        />
      )}
    </div>
  );
};

export default ReusableProductGrid;
